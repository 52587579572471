@font-face {
  font-family: 'Rubik';
  src: url('https://fonts.googleapis.com/css2?family=Rubik:ital@0;1&display=swap');
}

@font-face {
  font-family: 'Druk';
  src: url(./fonts/DrukTextWide-Medium.ttf);
  
}

@font-face {
  font-family: 'Franklin';
  src: url(./fonts/FRABK.TTF);
  font-weight: normal;
}

@font-face {
  font-family: 'Franklin';
  src: url(./fonts/FRADM.TTF);
  font-weight: bold;
}



body {
  overflow-x: hidden;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Franklin', sans-serif;
  font-weight: 400;
}